<template>
  <v-dialog
    v-model="deleteAchievementModal"
    width="800px"
    :retain-focus="false"
  >
    <v-card
      class="py-5 pb-5"
    >
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Você deseja excluir a conquista <b>{{achievement.title}}</b>?<br>
          <span class="subtitle-1">
            Os usuários que já ganharam essa conquista ainda as terão exibidas no perfil.
          </span>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="d-flex flex-no-wrap justify-center">
          <v-avatar
            tile
            left
            size="150px"
            color="#F7F7F7"
            class="ma-3 achievement-image"
          >
            <v-img
              :src="achievement.image"
              contain
              max-height="70%"
              @error="imageErrorHandler()"
            />
          </v-avatar>
        </div>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            :disabled="saving"
            @click="deleteAchievement()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Sim
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  props: {
    achievementData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      id: null,
      achievement: {
        name: ''
      }
    }
  },
  computed: {
    ...mapState(['achievements']),
    deleteAchievementModal: {
      get () {
        return this.$store.state.deleteAchievementModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteAchievementModal',
          value: val
        })
      }
    }
  },
  watch: {
    deleteAchievementModal (val) {
      if (val) {
        const { _id, image, title } = this.achievementData
        this.id = _id
        this.achievement.image = image
        this.achievement.title = title
      } else {
        this.shield = {
          title: '',
          image: ''
        }
        this.id = null
      }
    }
  },
  methods: {
    deleteAchievement () {
      this.saving = true
      this.$http.delete(`/achievements/${this.id}`)
        .then(() => {
          this.saving = false
          this.deleteAchievementModal = false
          this.$emit('removed')
          this.$toast.success('Cadastro removido com sucesso.')
        })
        .catch((err) => {
          this.saving = false
          this.$toast.error(err.response.data.message)
        })
    },
    imageErrorHandler () {
      this.achievement.image = imageOffIcon
    }
  }
}
</script>
